registerProcessor(
  'SampleAndHoldProcessor',
  class QuantizerProcessor extends AudioWorkletProcessor {
    static get parameterDescriptors() {
      return [
        {
          name: 'clock',
          defaultValue: 0,
          minValue: -1,
          maxValue: 1,
          automationRate: 'k-rate',
        },
        {
          name: 'signal',
          defaultValue: 0,
          automationRate: 'k-rate',
        },
      ];
    }

    lastClock = 0;
    value = 0;

    process(
      _: Float32Array[][],
      [[output]]: Float32Array[][],
      { signal: [signal], clock: [clock] }: { [param: string]: Float32Array },
    ): boolean {
      if (this.lastClock <= 0 && clock > 0) {
        this.value = signal;
        this.port.postMessage(['trigger']);
      }

      this.lastClock = clock;

      output.fill(this.value);

      return true;
    }
  },
);
